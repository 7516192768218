import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import React, { useRef, useEffect } from "react";
import { StructuredText } from "react-datocms";
import Allinone from "../components/Allinone";
import Clients from "../components/Clients";
import Layout from "../components/layout";
import Partners from "../components/Partners";
import TryDemo from "../components/TryDemo";
import theme from "../styles/theme";

const IndexPage = ({ location, data }) => {
  const { home, global, allDatoCmsIndustry } = data;
  const mobileHeroVideoRef = useRef();

  useEffect(() => {
    mobileHeroVideoRef.current.play();
  }, []);

  const allinoneData = {
    title: global.allInOneTitle,
    excerpt: global.allInOneExcerpt,
    cloudIcon: global.cloudIcon,
    products: global.products,
  };
  return (
    <Layout
      headerType="transparent"
      location={location}
      seo={home.seoMetaTags}
      logo={global.logo}
    >
      <main
        css={`
          margin: 0 auto;
          width: 100%;
          max-width: 1600px;
          box-sizing: border-box;
        `}
      >
        <section
          css={`
            position: relative;
            padding-left: 0;
            padding-right: 0;
            height: 100%;
            @media (min-width: 1600px) {
              margin-left: calc(-100vw / 2 + 1600px / 2);
              margin-right: calc(-100vw / 2 + 1600px / 2);
              .content {
                padding-left: calc(100vw / 2 - 1380px / 2) !important;
              }
            }
            svg {
              cursor: pointer;
            }

            .content {
              display: flex;
              box-sizing: border-box;
              flex-direction: column;
              justify-content: flex-end;
              height: 100%;
              padding-left: 120px;
              padding-bottom: 20px;
              max-width: 630px;
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              color: #fff;
              .button.whiteOutlines {
                margin-bottom: 60px;
                min-width: 159px;
                max-width: 160px;
                height: 52px;
                font-size: 21px;

                @media (max-width: 1350px) {
                  margin-bottom: 40px;
                  height: 38px;
                  font-size: 16px;
                }
                @media (max-width: 900px) {
                  margin-bottom: 20px;
                }
              }

              h1 {
                margin-top: 100px;
                font-size: 72px;
                margin-bottom: 0;
                ${theme.laptop} {
                  font-size: 58px;
                  margin-top: 80px;
                }
                ${theme.tablet} {
                  margin-top: 10px;
                }
                ${theme.mobile} {
                  font-size: 38px;
                  margin-top: 10px;
                }
              }
              p {
                font-size: 24px;
                line-height: 120%;
                margin-bottom: 24px;
              }
            }
            ${theme.laptop} {
              .content {
                padding-left: 80px;
              }
            }
            ${theme.tablet} {
              .content {
                padding-left: 40px;
                padding-right: 20px;
                p {
                  font-size: 18px;
                }
              }
            }
            ${theme.mobile} {
              height: 100%;
              .content {
                padding-left: 20px;
                padding-right: 20px;
                padding-bottom: 0;
                width: 100%;
                @media (max-width: 600px) {
                  align-items: center;
                }
              }
            }
          `}
        >
          <video
            width="100%"
            height="auto"
            autoPlay
            muted
            loop
            poster={data.home.videoPoster?.url}
            css={`
              display: none;
              object-fit: contain;
              overflow: hidden;
              width: 100%;
              @media (min-width: 600px) {
                display: block;
              }
            `}
          >
            <source src={data.home.video?.video.mp4Url} type="video/mp4" />
          </video>
          <video
            ref={mobileHeroVideoRef}
            width="100%"
            height="auto"
            autoPlay
            muted
            playsInline
            loop
            poster={data.home.videoPosterMobile?.url}
            css={`
              display: block;
              object-fit: contain;
              overflow: hidden;
              width: 100%;
              @media (min-width: 600px) {
                display: none;
              }
            `}
          >
            <source
              src={data.home.videoMobile?.video.mp4Url}
              type="video/mp4"
            />
          </video>
          <div className="content">
            <Link
              to="/contact"
              className="button whiteOutlines"
              css={`
                svg {
                  margin-left: 5px;
                }
              `}
            >
              {home.button}
              <svg
                width="18"
                viewBox="0 0 16 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.6643 9.79424C16.1107 9.35479 16.1107 8.64111 15.6643 8.20166L9.95 2.57666C9.50357 2.13721 8.77857 2.13721 8.33214 2.57666C7.88571 3.01611 7.88571 3.72978 8.33214 4.16924L12.1 7.87471H1.14286C0.510714 7.87471 0 8.37744 0 8.99971C0 9.62197 0.510714 10.1247 1.14286 10.1247H12.0964L8.33571 13.8302C7.88929 14.2696 7.88929 14.9833 8.33571 15.4228C8.78214 15.8622 9.50714 15.8622 9.95357 15.4228L15.6679 9.79775L15.6643 9.79424Z"
                  fill="white"
                />
              </svg>
            </Link>
          </div>
        </section>
        <section
          css={`
            flex-direction: column;
            padding-top: 110px;
            padding-bottom: 60px;
            @media (max-width: 600px) {
              padding-bottom: 30px;
            }
            align-items: center;
          `}
        >
          <div
            css={`
              max-width: 550px;
              text-align: center;
              margin: 0 auto;
              h3 {
                margin-top: 30px;
                font-size: 20px;
              }
            `}
            dangerouslySetInnerHTML={{ __html: data.home.overviewTitle }}
          />
          <div
            css={`
              display: grid;
              justify-content: center;
              padding-top: 20px;
              padding-bottom: 50px;
              grid-gap: 20px;
              width: 100%;
              grid-template-columns: repeat(6, 1fr);
              ${theme.max1100} {
                grid-template-columns: repeat(3, 1fr);
              }
              ${theme.max600} {
                grid-template-columns: repeat(1, 1fr);
              }
            `}
          >
            {data.home.overviewItems.map(i => (
              <div
                css={`
                  display: flex;
                  position: relative;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  background: #ffffff;
                  max-width: 256px;
                  width: 100%;
                  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.25);
                  border-radius: 10px;
                  padding: 50px 20px;
                  margin-top: 20px;
                  box-sizing: border-box;
                  @media (max-width: 600px) {
                    max-width: 356px;
                  }
                  img {
                    max-height: 130px;
                    height: 100%;
                    @media (max-width: 1400px) {
                      max-height: 100px;
                    }
                    @media (max-width: 1300px) {
                      max-height: 86px;
                    }
                    @media (max-width: 600px) {
                      max-height: 140px;
                    }
                  }
                  p {
                    text-align: center;
                    font-weight: 700;
                    @media (max-width: 600px) {
                      font-size: 15px;
                    }
                  }
                `}
              >
                <div
                  css={`
                    position: absolute;
                    top: -20px;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    border-radius: 50%;
                    width: 42px;
                    height: 42px;
                    padding: 8px;
                    box-sizing: border-box;
                    background: #2ab238;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  `}
                >
                  <Checked />
                </div>
                <img src={i.icon.url} alt={i.title} />
                <p>{i.title}</p>
              </div>
            ))}
          </div>

          <Link
            className={`button pinkFilled`}
            to={data.home.overviewCta[0].url}
          >
            {data.home.overviewCta[0].text}
          </Link>
        </section>
        <section
          css={`
            margin-top: 0;
            padding-top: 100px;
            @media (max-width: 800px) {
              flex-direction: column;
              padding-left: 0;
              padding-right: 0;
            }
            ${theme.max1200} {
              margin-top: 0px;
            }
            h2 {
              color: ${theme.pink};
              max-width: 640px;
              margin-bottom: 10px;
            }
            p {
              max-width: 640px;
              font-size: 21px;
              line-height: 130%;
              margin-bottom: 16px;
            }
            img {
              margin-top: 80px;
              width: 100%;
              object-fit: contain;
              object-position: 50% 50%;
            }
            .button {
              margin-top: 20px;
            }
            ul {
              list-style: none;
              padding-left: 0;
              p {
                margin-bottom: 6px;
                margin-top: 6px;
              }
            }
          `}
        >
          <div
            css={`
              display: flex;
              flex: 1;
              flex-direction: column;
              @media (max-width: 800px) {
                padding-left: 20px;
                padding-right: 20px;
              }
            `}
          >
            <StructuredText
              data={home.iContent}
              renderBlock={({ record }) => {
                switch (record.__typename) {
                  case "DatoCmsButton":
                    let cssClass = "default";
                    if (record.layout === "Pink Filled")
                      cssClass = "pinkFilled";
                    if (record.layout === "Pink Outlines")
                      cssClass = "pinkOutlines";
                    if (record.layout === "Grey Outlines")
                      cssClass = "greyOutlines";

                    return (
                      <div>
                        <Link className={`button ${cssClass}`} to={record.url}>
                          {record.text}
                        </Link>
                      </div>
                    );
                  case "DatoCmsImage":
                    return (
                      <div>
                        <img src={record.img.url} alt={record.img.alt} />
                      </div>
                    );
                  default:
                    return null;
                }
              }}
            />
          </div>
          <div
            css={`
              display: flex;
              flex: 1;
              flex-direction: column;
              @media (max-width: 600px) {
                padding-top: 60px;
              }
            `}
          >
            <video
              width="100%"
              height="auto"
              autoPlay
              playsInline
              muted
              loop
              poster={data.home.videoPosterAio?.url}
              css={`
                display: block;
                object-fit: contain;
                overflow: hidden;
                width: 100%;
              `}
            >
              <source src={data.home.videoAio?.video.mp4Url} type="video/mp4" />
            </video>
          </div>
        </section>
        <Allinone data={allinoneData} />
        <TryDemo data={data.global} />
        <section
          css={`
            flex-direction: column;
          `}
        >
          <h2>{home.industriesTitle}</h2>
          <div
            css={`
              display: grid;
              justify-content: center;
              grid-gap: 20px;
              grid-template-columns: repeat(3, 1fr);
              ${theme.max1100} {
                grid-template-columns: repeat(2, 1fr);
              }
              ${theme.max600} {
                grid-template-columns: repeat(1, 1fr);
              }
              > a {
                width: 100%;
                height: auto;
                display: flex;
                position: relative;
                justify-content: center;
                align-items: center;
                color: #fff;
                .img {
                  position: absolute;
                  width: 100%;
                }
                .layer {
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  background: rgba(54, 54, 54, 0.7);
                  mix-blend-mode: multiply;
                }
                .content {
                  position: absolute;
                  text-align: center;
                  padding: 20px;
                }
                .button {
                  margin-top: 24px;
                  height: 52px;
                  color: #fff;
                  border-color: #fff;
                }
                h3 {
                  margin: 0;
                  font-weight: 900;
                  font-size: 42px;
                  line-height: 110.4%;
                  @media (max-width: 1500px) {
                    font-size: 36px;
                  }
                  ${theme.laptop} {
                    font-size: 32px;
                  }
                  ${theme.max900} {
                    font-size: 32px;
                  }
                }
                p {
                  font-size: 16px;
                  line-height: 118%;
                  ${theme.max900} {
                    font-size: 16px;
                  }
                }

                &:hover .layer {
                  background: #474747 !important;
                  mix-blend-mode: normal;
                }
              }
            `}
          >
            {allDatoCmsIndustry.edges.map(item => {
              return (
                <Link to={`/${item.node.slug}`}>
                  <Img
                    className="img"
                    fluid={item.node.thumbnail.fluid}
                    alt={item.node.thumbnail.alt}
                    imgStyle={{
                      objectFit: "contain",
                    }}
                  />
                  <div className="layer" />
                  <div className="content">
                    <h3>{item.node.title}</h3>
                    <p>{item.node.text}</p>
                    <button className="button">Read more</button>
                  </div>
                </Link>
              );
            })}
          </div>
        </section>
        <section
          css={`
            padding-top: 100px;
            flex-direction: column;
            .iframeContainer {
              position: relative;
              padding-bottom: 56.25%;
              height: 0;
              overflow: hidden;
              max-width: 100%;
            }

            .iframeContainer iframe,
            .iframeContainer object,
            .iframeContainer embed {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }
          `}
        >
          <h2>{home.videoTitle}</h2>
          <div className="iframeContainer">
            <iframe
              title="Yonoton Explained"
              src={`https://player.vimeo.com/video/${home.videoShow.providerUid}?title=0&byline=0&portrait=0&sidedock=0`}
              frameborder="0"
              allow="autoplay; fullscreen"
              allowfullscreen
            ></iframe>
          </div>
        </section>

        <Clients
          dataTitle={data.home.clientsTitle}
          dataClients={data.clients}
        />

        <section
          css={`
            flex-direction: column;
            margin-top: 80px;
            margin-bottom: 0;
            h2 {
              margin: 0 0 40px;
            }
            a.allNews {
              max-width: 220px;
              margin: 80px auto 0;
              ${theme.mobile} {
                margin-top: 20px;
              }
            }
          `}
        >
          <h2>{home.newsTitle}</h2>
          <div
            css={`
              display: grid;
              justify-content: center;
              grid-gap: 20px;
              grid-template-columns: repeat(3, 1fr);
              ${theme.max600} {
                grid-template-columns: repeat(1, 1fr);
                > div:not(:last-child) {
                  border-right: none !important;
                }
                > div {
                  margin-top: 20px;
                  margin-bottom: 30px;
                }
              }
              > div:not(:last-child) {
                border-right: 1px solid ${theme.pink};
              }

              > div {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                width: 100%;
                padding-right: 30px;
                box-sizing: border-box;
                h3 {
                  color: ${theme.grey};
                  margin: 0;
                  font-weight: 900;
                  font-size: 24px;
                  line-height: 110.4%;
                  margin-bottom: 20px;
                }
                p {
                  font-size: 18px;
                  line-height: 118%;
                  margin: 0 0 20px;
                  //flex-grow: 1;
                }
                .date {
                  margin: 0 0 20px;
                }
                .button {
                  background: ${theme.pink};
                  color: #fff;
                }
              }
            `}
          >
            {data.news.edges.map(item => {
              return (
                <div>
                  <h3>{item.node.title}</h3>
                  <span className="date">{item.node.published}</span>
                  <p>{item.node.excerpt}</p>
                  <div>
                    <Link className="button" href={`/news/${item.node.slug}`}>
                      Read more
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
          <Link to="/news" className="button allNews">
            All news
          </Link>
        </section>

        <section
          css={`
            display: none;
            padding-top: 100px;
            padding-bottom: 50px;
            flex-direction: column;
          `}
        >
          <h2>{home.peopleTitle}</h2>
          <div
            css={`
              display: grid;
              justify-content: center;
              grid-gap: 38px;
              grid-template-columns: repeat(4, 1fr);
              max-width: 1363px;
              ${theme.max1300} {
                grid-template-columns: repeat(3, 1fr);
                grid-gap: 38px;
              }
              ${theme.max900} {
                grid-template-columns: repeat(2, 1fr);
                > div .img {
                  max-height: 460px !important;
                }
              }
              ${theme.max600} {
                grid-template-columns: repeat(1, 1fr);
              }
              > div {
                flex: 1;
                width: 100%;
                display: flex;
                flex-direction: column;
                .img {
                  position: relative;
                  display: flex;
                  width: 100%;
                  max-height: 390px;
                  border-radius: 2px;
                  img {
                    object-fit: cover !important;
                  }
                }
                strong {
                  font-weight: 900;
                  font-size: 24px;
                  margin-bottom 
                }
                p:first-child {
                  margin-top: 16px;
                }
                p {
                  font-size: 16px;
                  line-height: 130%;
                  margin-top: 10px;
                }
                ${theme.max900} {
                  > div h3 {                    
                    font-size: 28px;
                  }
                }
              }
            `}
          >
            {global.people.map(item => {
              return (
                <div>
                  <Img
                    className="img"
                    fluid={item.img.fluid}
                    alt={item.img.alt || item.img.filename}
                    imgStyle={{
                      objectFit: "contain",
                    }}
                  />
                  <div
                    className="content"
                    dangerouslySetInnerHTML={{ __html: item.content }}
                  />
                </div>
              );
            })}
            <div
              css={`
                background: ${theme.pink} !important;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                color: #fff;
                height: 385px;
                border-radius: 2px;
                .button {
                  margin-top: 15px;
                  &:hover {
                    opacity: 0.9;
                  }
                }
                h3 {
                  margin-top: 0;
                }
                .next h3 {
                  font-size: 26px;
                  margin-top: 15px;
                  font-weight: 900;
                }
              `}
            >
              <div className="content next">
                <h3>Are you next?</h3>
                <a
                  href="mailto:rekry@yonoton.com"
                  className="button whiteOutlines"
                >
                  rekry@yonoton.com
                </a>
              </div>
            </div>
          </div>
        </section>

        <Partners
          dataTitle={home.partnersTitle}
          dataPartners={global.partners}
        />
        <section
          css={`
            display: flex;
            margin-bottom: 100px;
            flex-wrap: wrap-reverse;
            ${theme.max1600} {
              padding-left: 0;
              padding-right: 0;
            }
            ${theme.tablet} {
              margin-top: 120px;
              margin-bottom: 120px;
            }
            div:first-child {
              flex: 1;
            }
            .img {
              width: 100%;
              img {
                width: 100%;
                object-fit: cover;
                object-position: 50% 50%;
              }
            }
            .content {
              padding-left: 100px;
              padding-right: 20px;
              flex: 1;
              display: flex;
              flex-direction: column;
              justify-content: center;
              h2 {
                margin: 0;
              }
              .button {
                margin-top: 20px;
                color: ${theme.pink};
              }
              ${theme.tablet} {
                padding-left: 20px;
              }
            }
            ${theme.mobile} {
              margin-top: 100px;
              margin-bottom: 100px;
              .content {
                flex-basis: 100%;
                margin-top: 40px;
                margin-bottom: 40px;
              }
            }
          `}
        >
          <div>
            <Img
              className="img"
              fluid={home.techImage.fluid}
              alt={home.techImage.alt}
            />
          </div>
          <div className="content">
            <StructuredText
              className="content"
              data={home.techContent}
              renderBlock={({ record }) => {
                switch (record.__typename) {
                  case "DatoCmsButton":
                    let cssClass = "default";
                    if (record.layout === "Pink Filled")
                      cssClass = "pinkFilled";
                    if (record.layout === "Pink Outlines")
                      cssClass = "pinkOutlines";
                    if (record.layout === "Grey Outlines")
                      cssClass = "greyOutlines";

                    return (
                      <div>
                        <Link className={`button ${cssClass}`} to={record.url}>
                          {record.text}
                        </Link>
                      </div>
                    );
                  default:
                    return null;
                }
              }}
            />
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query HomeQuery {
    allDatoCmsIndustry(sort: { fields: [position], order: ASC }) {
      edges {
        node {
          title
          text
          slug
          thumbnail {
            fluid(maxWidth: 512) {
              ...GatsbyDatoCmsFluid
            }
            alt
          }
        }
      }
    }
    news: allDatoCmsArticle(
      limit: 3
      sort: { fields: [published], order: DESC }
    ) {
      edges {
        node {
          title
          excerpt
          slug
          published(formatString: "DD.MM.YYYY", locale: "en")
        }
      }
    }
    clients: allDatoCmsClient(sort: { fields: [position], order: ASC }) {
      edges {
        node {
          title
          slug
          text
          thumbnail {
            fluid(maxWidth: 512) {
              ...GatsbyDatoCmsFluid
            }
            alt
          }
        }
      }
    }
    global: datoCmsGlobal {
      trydemoTitle
      trydemoLead
      trydemoImage {
        fluid(maxWidth: 640) {
          ...GatsbyDatoCmsFluid
        }
      }
      allInOneTitle
      allInOneExcerpt
      cloudIcon {
        url
        alt
      }
      products {
        ... on DatoCmsProductItem {
          title
          content
          linkToPage {
            slug
          }
          img {
            fluid(maxWidth: 512) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
      people {
        ... on DatoCmsPerson {
          content
          img {
            fluid(maxWidth: 512) {
              ...GatsbyDatoCmsFluid
            }
            filename
            alt
          }
        }
      }
      logo {
        fluid(maxWidth: 700) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
      partners {
        ... on DatoCmsPartner {
          img {
            fluid(maxWidth: 512) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
    home: datoCmsHome {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      lead
      button
      video {
        video {
          streamingUrl
          mp4Url
        }
      }
      videoPoster {
        url
      }
      videoMobile {
        video {
          streamingUrl
          mp4Url
        }
      }
      videoPosterMobile {
        url
      }
      videoAio {
        video {
          streamingUrl
          mp4Url
        }
      }
      videoPosterAio {
        url
      }
      overviewTitle
      overviewItems {
        title
        icon {
          url
        }
      }
      overviewCta {
        url
        text
        layout
      }
      iContent {
        value
        blocks {
          ... on DatoCmsButton {
            __typename
            id: originalId
            url
            text
            layout
          }
          ... on DatoCmsImage {
            __typename
            id: originalId
            img {
              url
              alt
            }
          }
        }
      }
      industriesTitle
      videoTitle
      videoShow {
        url
        providerUid
      }
      clientsTitle
      techImage {
        fluid(maxWidth: 700) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
      techContent {
        value
        blocks {
          ... on DatoCmsButton {
            __typename
            id: originalId
            url
            text
          }
        }
      }
      newsTitle
      peopleTitle
      partnersTitle
    }
  }
`;

const Checked = () => (
  <svg
    width="31"
    height="23"
    viewBox="0 0 31 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.9245 1.39605C30.783 2.1885 30.783 3.5753 29.9245 4.36775L13.0189 21.2734C12.2264 22.1319 10.8396 22.1319 10.0472 21.2734L1.59434 12.8206C0.735849 12.0281 0.735849 10.6413 1.59434 9.84888C2.38679 8.99039 3.77358 8.99039 4.56604 9.84888L11.5 16.7828L26.9528 1.39605C27.7453 0.53756 29.1321 0.53756 29.9245 1.39605Z"
      fill="white"
    />
  </svg>
);
