import Img from "gatsby-image";
import React from "react";
import { Honeypot, NetlifyForm } from "react-netlify-forms";
import { Link } from "gatsby";
import theme from "../styles/theme";

const TryDemo = ({ data }) => {
  return (
    <section
      id="trynow"
      css={`
        margin-top: 50px;
        margin-bottom: 100px;
        padding-left: 0;
        padding-right: 0;
        position: relative;
        background: #e00084;
        min-height: 500px;
        color: #fff;
        display: flex;
        align-items: center;
        @media (min-width: 1600px) {
          margin-left: calc(-100vw / 2 + 1600px / 2);
          margin-right: calc(-100vw / 2 + 1600px / 2);
        }
        h2 {
          color: #fff;
          margin-top: 0;
          margin-bottom: 10px;
        }
        .formBox {
          flex: 1;
          display: flex;
          flex-direction: column;
          color: #fff;
          justify-content: center;
          padding-left: 100px;
          min-width: 300px;
          max-width: 400px;
          margin-top: 40px;
          ${theme.max900} {
            padding-left: 40px;
          }
          ${theme.max641} {
            padding-top: 40px;
            padding-left: 20px;
            padding-right: 20px;
            min-width: 200px;
          }
        }
        .imgBox {
          flex: 1;
          max-height: 460px;
          img {
            max-height: 460px;
            max-width: 600px;
            margin: 0 auto;
          }
        }
        .messages {
          height: 80px;
        }
        ${theme.max700} {
          flex-wrap: wrap-reverse;
          > div {
            flex-basis: 100% !important;
          }
          .imgBox {
            width: 80%;
            margin-top: 60px;
          }
          .formBox {
            padding-top: 0;
            padding-bottom: 40px;
          }
        }
      `}
    >
      <div
        className="formBox"
        css={`
          @media (min-width: 1600px) {
            padding-left: calc(100vw / 2 - 1380px / 2) !important;
          }
          form {
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            width: 100%
            max-width: 400px;
            input {
              border: 3px solid #e00084;
              height: 52px;
              padding: 8px;
              border-radius: 10px;
              font-size: 18px;
              margin: 5px 0 5px;
              &:active,
              &:focus {
                border: 3px solid #e00084;
              }
            }
            .buttonSubmit {
              background: #fff;
              color: #474747;
              height: 52px;
              width: 180px;
              margin-top: 20px;
              border-radius: 10px;
              &:hover {
                opacity: 0.95;
                transition: opacity 0.2s;
              }
            }
            a.button {
              margin-top: 10px;
              color: #fff;
              border-color: #fff;
              height: 48px;
              width: 180px;
              border-radius: 10px;
              &:hover {
                opacity: 0.95;
              }
            }
          }
        `}
      >
        <h2>{data.trydemoTitle}</h2>
        <p>{data.trydemoLead}</p>
        <Link
          to="/contact"
          className="button whiteOutlines"
          css={`
            margin-top: 30px;
            svg {
              margin-left: 5px;
            }
          `}
        >
          Book demo
          <svg
            width="18"
            viewBox="0 0 16 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.6643 9.79424C16.1107 9.35479 16.1107 8.64111 15.6643 8.20166L9.95 2.57666C9.50357 2.13721 8.77857 2.13721 8.33214 2.57666C7.88571 3.01611 7.88571 3.72978 8.33214 4.16924L12.1 7.87471H1.14286C0.510714 7.87471 0 8.37744 0 8.99971C0 9.62197 0.510714 10.1247 1.14286 10.1247H12.0964L8.33571 13.8302C7.88929 14.2696 7.88929 14.9833 8.33571 15.4228C8.78214 15.8622 9.50714 15.8622 9.95357 15.4228L15.6679 9.79775L15.6643 9.79424Z"
              fill="white"
            />
          </svg>
        </Link>
      </div>
      <div className="imgBox">
        <Img
          className="img"
          fluid={data.trydemoImage.fluid}
          alt={data.trydemoImage.alt}
          imgStyle={{
            objectFit: "contain",
          }}
        />
      </div>
    </section>
  );
};

export default TryDemo;
